<!--
* @Description 解决方案-VR技术
* @fileName index.vue
* @MethodAuthor stjh
* @Date 2023-06-30 17:20:54
-->
<template>
  <div class="aboutContainer">
    <div class="top"></div>
    <div class="listBox">
      <div
        class="rjCard"
        v-for="(item, index) in list"
        :key="index"
        @mouseover="mouseoverHandle(item, index)"
        @mouseleave="mouseleaveHandle(item, index)"
      >
        <div class="rjCardCon">
          <img :src="item.img" class="cardImg" />
          <div class="content">
            <p class="title">{{ item.name }}</p>
            <p class="con">{{ item.description }}</p>
            <div class="btm">
              <span>{{ item.updateTime }} </span>
              <span>{{ item.size }}</span>
            </div>
          </div>
        </div>
        <div v-show="item.show" class="zzc">
          <el-button round @click="download(item)">Download</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { softwareListPage } from "@/api/software.js";
export default {
  data() {
    return {
      list: [],
    };
  },

  created() {
    this.getLists();
  },

  methods: {
    /*
     * @Description 鼠标经过
     * @MethodAuthor stjh
     * @Date 2023-06-29 10:28:57
     **/
    mouseoverHandle(item, index) {
      this.$set(item, "show", true);
    },
    /*
     * @Description 鼠标离开
     * @MethodAuthor stjh
     * @Date 2023-06-29 10:28:57
     **/
    mouseleaveHandle(item, index) {
      this.$set(item, "show", false);
    },

    getLoading() {
      const loading = this.$message({
        message: this.$t(`common.Loading`),
        type: "success",
        iconClass: "el-icon-loading",
        duration: 0,
      });
      return loading;
    },

    download(item) {
      // this.downloadBefore(item.path).then((res) => {
      //   this.downloadFile(res.data, item.fileName);
      // });
      var a = document.createElement("a");
      a.href = item.path;
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      a.remove();
    },

    downloadBefore(url) {
      const loading = this.getLoading();
      return axios({
        url: url,
        method: "get",
        responseType: "blob",
      })
        .catch((e) => {
          console.log(e);
          this.$message.error("资源请求错误");
          loading.close();
        })
        .finally(() => {
          loading.close();
        });
    },

    downloadFile(obj, name, suffix) {
      const url = window.URL.createObjectURL(new Blob([obj]));
      const link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    getLists() {
      const params = {
        pageSize: 999,
        pageNum: 1,
      };
      softwareListPage(params).then((res) => {
        if (res.code === 200) {
          this.list = res.rows;
        }
      });
    },
  },
};
</script>
<style scoped>
::v-deep .el-button.is-round {
  width: 248px;
  height: 73px;
  border-radius: 37px;
  border: 2px solid #ffffff;
  background: transparent;

  font-size: 40px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
}
</style>
<style lang="scss" scoped>
.aboutContainer {
  background: #ffffff;
  padding-bottom: 100px;

  .top {
    width: 100%;
    height: 200px;
    // background: #b6bfd6;
    background-image: url("~@/assets/images/downloadImg/banner@2x.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .listBox {
    width: 1200px;
    margin: 0 auto;
    // padding-left: 377px;
    // padding-right: 343px;
    display: flex;
    flex-wrap: wrap;
    .rjCard {
      position: relative;
      width: 360px;
      height: 400px;
      border-radius: 12px 12px 12px 12px;
      opacity: 1;
      border: 2px solid #dbdbdb;
      margin-top: 56px;
      margin-right: 60px;
      overflow: hidden;

      // 每行最右侧的那个不设置右外边距
      &:nth-child(3n + 3) {
        margin-right: 0;
      }
      .rjCardCon {
        z-index: 2;
        .cardImg {
          width: 100%;
          height: 180px;
          object-fit: cover;
        }
        .content {
          height: 220px;
          padding: 0 18px;
          .title {
            font-size: 18px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #1d1d1f;
            line-height: 21px;
            padding: 20px 0;
          }
          .con {
            height: 125px;
            font-size: 16px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(29, 29, 31, 0.6);
            line-height: 19px;
            padding: 0;
            word-wrap: break-word;
          }

          .btm {
            display: flex;
            justify-content: space-between;

            font-size: 16px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: rgba(29, 29, 31, 0.6);
          }
        }
      }
    }

    .zzc {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 10;
      width: 360px;
      height: 400px;
      background: rgba(0, 0, 0, 0.67);
      border-radius: 12px 12px 12px 12px;
      opacity: 1;

      text-align: center;
      // line-height: 400px;
      padding: 164px 0;
    }
  }
}
</style>
