import request from "@/utils/request";

// 软件下载列表
export function softwareListPage(query) {
    let language = localStorage.getItem('dnn-cw-lang') == 'English' ? 'en' : 'zh'
    return request({
        url: "/client/tbFile/clientTbFileListPage",
        method: "get",
        params: { ...query,  language},
    });
}
